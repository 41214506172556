import '../App.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import NetInfo from "@react-native-community/netinfo";
import Select from 'react-select'
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import kioskCheesy from '../images/kiosk-cheesy.png'
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";
import bell from '../bell.mp3'
import splash from '../images/splash.png';
import logo from '../images/de.png'
import mydoge from '../images/mydoge.webp'
import coinbase from '../images/coinbasewallet.png'
import exodus from '../images/exodus.png'
import trust from '../images/trustwallet.png'
import safepal from '../images/safepal.png'
import atomic from '../images/atomic.jpeg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Canvas from '../components/Canvas'
import SupportedWallets from '../components/SupportedWallets'
import Splash from './Splash'
import DataEntry from './DataEntry'
import Tip from './Tip'
import { Linking } from 'react-native';
import { Buffer } from 'buffer'; // Ensure Buffer is available
import bitcoin from 'bitcoinjs-lib';
import { useLocation } from 'react-router-dom';
// import { BIP32Factory } from 'bip32';
// import * as bitcoin from 'bitcoinjs-lib';
// const bitcoin = require('bitcoinjs-lib');
const bip39 = require('bip39');
//const bip32 = require('bip32');
const hdkeystuff = require('ethereum-cryptography/hdkey');
//const Mnemonic = require('bitcore-mnemonic');
const bitcore = require('bitcore-lib-doge');
const DOGECOIN_NETWORK = {
  messagePrefix: '\x19Dogecoin Signed Message:\n',
  bech32: 'doge',
  bip32: {
    public: 0x02facafd,
    private: 0x02fac398
  },
  pubKeyHash: 0x1e,
  scriptHash: 0x16,
  wif: 0x9e,
};
window.Buffer = Buffer; // Set Buffer globally if not already set

export class Main extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
		page: -2, 
		numerals: [],
		order: [],
		keypadToggle: 'balance',
		price: 1,
		completed: false,
		squareOrder: {total_money: {}, total_tip_money: {}},
		toastOrder: {total_money: {}, total_tip_money: {}},
		view: 'admin'
	    };

	    this.audio = new Audio(bell)

	    this.handleClickDoge = this.handleClickDoge.bind(this);
	    this.handleKeyClick = this.handleKeyClick.bind(this);
	    this.handleContinueClick = this.handleContinueClick.bind(this);
	    this.total = this.total.bind(this)
	    this.setQRCode = this.setQRCode.bind(this);
	    this.checkPayment = this.checkPayment.bind(this);
	    this.paymentUrl = this.paymentUrl.bind(this)
	    this.handleTip = this.handleTip.bind(this)
	    this.toggleEntry = this.toggleEntry.bind(this)
	    this.handleClickSplash = this.handleClickSplash.bind(this)
	    this.handleReset = this.handleReset.bind(this)
	    this.handleAdminClick = this.handleAdminClick.bind(this)
	    this.handleChooseWallet = this.handleChooseWallet.bind(this)
	    this.handleClickCreateWallet = this.handleClickCreateWallet.bind(this)
	    this.handleEditStoreId = this.handleEditStoreId.bind(this);
	    this.handleClickPayout = this.handleClickPayout.bind(this);
	}

  componentDidMount() {
    const checkAndSetConfig = async () => {
      const keyPairs = [
          //{storeKey: 'store-id', stateKey: 'storeId'},
          {storeKey: 'api-key', stateKey: 'apiKey'},
          {storeKey: 'posMode', stateKey: 'posMode'},
          {storeKey: 'merchantId', stateKey: 'merchantId'},
          {storeKey: 'currency', stateKey: 'currency'},
          {storeKey: 'tipsMode', stateKey: 'tipsMode'},
	  {storeKey: 'xpriv', stateKey: 'walletXPriv'},
      ]
      for (var i = 0; i < keyPairs.length; i++) {
        var keyPair = keyPairs[i]
        //const storeKey = 'store-id'
        const storeVal = await AsyncStorage.getItem(keyPair.storeKey)
        if (storeVal) {
          this.setState({[keyPair.stateKey]: storeVal})
        }
      }

	const queryString = window.location.search;
	const queryParams = new URLSearchParams(queryString);
	let invoiceMode = queryParams.get('invoiceMode');
	this.setState({invoiceMode});
	let storeId = queryParams.get('store_id');
	if (storeId) {
	    this.setState({storeId: storeId})
	    // get utxos balance
	    fetch(`/balance?store_id=${storeId}`).then(res => res.json()).then(utxos => {
//		console.log(body)
//		var utxos = body
		console.log('got balance for this merchant...')
		console.log(utxos)
		// var utxos = payload.utxos;
		var amount = 0;
		for (var i = 0; i < utxos.length; i++) {
		    var utxo = utxos[i];
		    // var oldTx = new bitcore.Transaction(utxo.hex)
		    var uo = utxo // oldTx.outputs[utxo.n]
		    amount += uo.satoshis
		}
		this.setState({balance: amount});
	    });
	}
	let view = queryParams.get('view');
	if (view) {
	    this.setState({view});
	}
    }
      checkAndSetConfig()
  }

  handleClickSplash() {
    NetInfo.fetch().then((state) => {
          if (!state.isConnected) {
            window.alert(
              "No Connection. Much sorry. So interrupt.\nPlease check your internet connection and try again.",
              [
                { text: "OK" }
              ],
              { cancelable: false }
            );
            this.handleReset();
          }
        });

    if (this.state.posMode === 'square' || this.state.posMode === 'toast') {
      let eventUrl = `https://doge.b3-well.com/square_events.json?merchant_id=${this.state.merchantId}`
      fetch(eventUrl).then(
        res => res.json()
      ).then(
        posEvent => {
          if (posEvent.id) {
          // this.setState({tipSelect: 'tip-no'}, this.handleContinueClick)
            let posMode = this.state.posMode
            let stats
            if (posMode === 'square') {
              stats = 'Confirm order #' + posEvent.order.id + ' for total $' + (posEvent.order.total_money.amount/100).toFixed(2)
            } else if (posMode === 'toast') {
              stats = 'Confirm order #' + posEvent.data.id + "\nFor total $" + posEvent.total_money.amount.toFixed(2)
            }
            let confirm = window.confirm(stats)
            if (confirm) {
              this.handleContinueClick()
            } else {
              this.setState({page: 0})
            }
          } else {
            this.setState({page: 0})
          }
        }
      )
    } else {
      console.log('click')
      if (this.state.storeId === 0) {
        this.setState({page: 2})
      } else {
        this.setState({page: 0})
      }
    }
  }

	handleClickDoge() {
    if (this.state.numerals.length === 0) {
      return
    }
    if (this.state.order.length === 0) {
      return
    }
    if (this.state.tipsMode === 'false') {
      // this.setState({page: 3});
      this.handleContinueClick();
    } else {
  		this.setState({page: 2});
    }
	}

	handleKeyClick(e) {
  let input;
  if (e.type === 'click') {
    input = e.target.innerText;
  } else if (e.type === 'keydown') {
    if (e.key === 'Backspace') {
      input = 'DEL';
    } else if (e.key === 'Enter') {
      return; // Ignore Enter key
    } else {
      input = e.key;
    }
  }

  if (input === "C") {
    console.log('clear')
    if (this.state.keypadToggle === 'balance') {
      this.setState({numerals: []});
    } else if (this.state.keypadToggle === 'order') {
      this.setState({order: []});
    }
    return
  }
  if (input === 'DEL') {
    if (this.state.keypadToggle === 'balance') {
      this.setState((state, props) => ({
        numerals: state.numerals.slice(0,-1)
      }))
    } else if (this.state.keypadToggle === 'order') {
      this.setState((state, props) => ({
        order: state.order.slice(0,-1)
      }))
    }
    return
  }

  if (this.state.keypadToggle === 'balance') {
    if (/^\d$/.test(input)) {
      this.setState((state, props) => ({
        numerals: [...state.numerals, input]
      }))
    }
  } else if (this.state.keypadToggle === 'order') {
    this.setState((state, props) => ({
      order: [...state.order, input]
    }))
  }
}

  handleTip(id) {
    console.log(id)
    this.setState({tipSelect: id})
    if (id === 'tip-cust') {
      this.tipPrompt('Enter tip amount')
    }
  }

  tipPrompt(msg) {
    var amount = window.prompt(msg)
    if (amount === 'asdffdsa') {
      window.location = '/admin'
    } else {
      var regex  = /^\d+(?:\.\d{0,2})$/;
      if (regex.test(amount) ) {
        this.setState({customTip: parseFloat(amount)})
      } else {
        this.tipPrompt('Please enter valid tip amount in dollars and cents')
      }
    }
  }

  toggleEntry(n) {
    this.setState({keypadToggle: n})
  }

	handleContinueClick() {
    console.log('continue click>>>')
    let posMode = this.state.posMode
    console.log(this.state.tipSelect)
    console.log(posMode)
    console.log(this.state.tipsMode)
    console.log('<<<')
    
    if (!this.state.tipSelect && posMode !== 'square' && posMode !== 'toast' && this.state.tipsMode !== 'false') {
      return
    }
		this.setState({page: 3});
    let priceUrl = 'https://doge.b3-well.com/price'
    fetch(priceUrl)
      .then(res => res.json())
      .then(payload => {
        console.log(payload)
        let price = this.state.currency === 'ltc' ? payload.data.LTC.price : payload.data.DOGE.price
        this.setState({price: price})
        console.log(price)

        const walletHost = this.state.currency === 'ltc' ? 'btc.dogeexpress.io' : 'doge.b3-well.com'
        if (this.state.posMode === 'square' || this.state.posMode === 'toast') {
          let eventUrl = `https://doge.b3-well.com/square_events.json?merchant_id=${this.state.merchantId}`
          fetch(eventUrl).then(
              res => res.json()
          ).then(
              event => {
                let amount_usd
                let amount_doge
                let tip_doge
                let amount_total_doge
                let order_id

                if (this.state.posMode === 'square') {
                  this.setState({squareOrder: event.order})

                  amount_usd = event.order.total_money.amount/100
                  amount_doge = amount_usd/price
                  tip_doge = (event.order.total_tip_money.amount/100)/price
                  amount_total_doge = amount_doge // + tip_doge
                  order_id = event.order.id
                } else {
                  this.setState({toastOrder: event})

                  amount_usd = event.total_money.amount
                  amount_doge = amount_usd/price
                  tip_doge = (event.total_tip_money.amount)/price
                  amount_total_doge = amount_doge // + tip_doge
                  order_id = event.id
                }

                let keyVals = [
                  `order_id=${order_id}`,
                  `amount=${amount_total_doge}`,
                  `tip=${tip_doge}`,
                  `store_id=${this.state.storeId}`,
                  `merchant_id=${this.state.merchantId}`,
                  `coin=${this.state.currency}`,
                ]
                if (this.state.apiKey) {
                  keyVals.push(`key=${this.state.apiKey}`)
                }
                let rparams = keyVals.join('&')
                let walletUrl = `${walletHost}/payments/new?${rparams}`

                fetch(`https://${walletUrl}`).then(resaddr => resaddr.text())
                  .then(txtaddr => {
                    let addr = txtaddr;

                    let paymentAddr = addr.trim()
                    let paymentAmount = amount_total_doge
                    this.setState({paymentAddr, paymentAmount})
                    // console.log(paymentUrl)
                    setTimeout(this.checkPayment, 500, addr)
                    // QRCode.toDataURL(paymentUrl, this.setQRCode);
                })

              }
          )
        } else {
          let amount_usd = this.total()*0.75
          let amount_doge = amount_usd/price
          let tip_doge = this.tip()/price
          let amount_total_doge = amount_doge + tip_doge
          let keyVals = [
            `order_id=${this.state.order.join('')}`,
            `amount=${amount_total_doge}`,
            `tip=${tip_doge}`,
            `store_id=${this.state.storeId}`,
            `coin=${this.state.currency}`,
          ]
          if (this.state.apiKey) {
            keyVals.push(`key=${this.state.apiKey}`)
          }
          let rparams = keyVals.join('&')
          let walletUrl = `${walletHost}/payments/new?${rparams}`
          fetch(`https://${walletUrl}`).then(resaddr => resaddr.text())
            .then(txtaddr => {
              let addr = txtaddr;

              let paymentAddr = addr.trim()
              let paymentAmount = amount_total_doge
              this.setState({paymentAddr, paymentAmount})
              // console.log(paymentUrl)
              setTimeout(this.checkPayment, 500, addr)
              // QRCode.toDataURL(paymentUrl, this.setQRCode);
          })
        }

      });
	}

  paymentUrl() {
    let protocol = this.state.currency === 'ltc' ? 'litecoin' : 'dogecoin'
    let url = `${protocol}:${this.state.paymentAddr}`
    if (this.state.walletApp && (this.state.walletApp.value !== 'Coinbase')) {
      url = url + `?amount=${this.state.paymentAmount}`
    }
    return url
  }

  handleReset() {
    window.location = '/'
  }

  handleChooseWallet(walletApp) {
    this.setState({walletApp}, () => {
        QRCode.toDataURL(this.paymentUrl(), this.setQRCode)
      }
    )
  }

	checkPayment(paymentAddr) {
		let checkUrl
    if (this.state.currency === 'ltc') {
  		checkUrl = `https://cors-anywhere.herokuapp.com/https://btc.dogeexpress.io/payments/${paymentAddr}.json?full=1&coin=ltc`
    } else {
      checkUrl = `https://doge.b3-well.com/payments/${paymentAddr}.json?full=1`
    }
		fetch(checkUrl).then(res => res.json())
			.then(payload => {
				console.log('bal rcvd:')
				console.log(payload.received)
				if (payload.received >= payload.amount*0.98) {
					console.log('payment received')
					this.setState({rcvd: payload.received, remainder: null})
          this.audio.play()
          setTimeout(()=>{window.location='/'}, 1000 * 60 * 5)
        } else if (payload.received > 0) {
          let remainder = payload.amount-payload.received
					this.setState({rcvd: payload.received, remainder})
					console.log(`partial payment received (${payload.received} of ${payload.amount})...`)
          let paymentAmount = remainder
          this.setState({paymentAmount}, () => {
              QRCode.toDataURL(this.paymentUrl(), this.setQRCode)
            }
          )
					setTimeout(this.checkPayment, 500, paymentAddr)
				} else {
					console.log('payment pending...')
					setTimeout(this.checkPayment, 500, paymentAddr)
				}
			})
	}

	setQRCode(err, url) {
		this.setState({qrCodeUrl: url});
		// const socket = socketIO.connect("http://b3-well.com:4567");
		// socket.on("sendSockets", (sockets) => {
		// 	console.log(`sockets: ${sockets}`)
		//     });
	}

    handleAdminClick() {
	console.log('admin clik');
	this.setState({view: 'admin'});
	/*
    const pw = window.prompt('Password')
    if (pw === 'asdffdsa') {
      window.location = '/admin'
    }
*/
  }

	getDogeUSDRate() {
		let price = this.state.price
    return price;
	}

	total() {
    if (this.state.posMode === 'square') {
      return (this.state.squareOrder.total_money.amount||0)/100
    } else if(this.state.posMode === 'toast') {
      return (this.state.toastOrder.total_money.amount||0)
    } else {
      if (this.state.numerals.length === 0) {
        return 0
      } else {
    		return (parseFloat(this.state.numerals.join(''))/100);
      }
    }
	}

  tip() {
    if (this.state.posMode === 'square') {
      return (this.state.squareOrder.total_tip_money.amount||0)/100
    } else if(this.state.posMode === 'toast') {
      return (this.state.toastOrder.total_tip_money.amount||0)
    } else {
      var tip = 0
      if (this.state.tipSelect === 'tip-5-flat') {
        return 5
      } else if (this.state.tipSelect === 'tip-10-flat') {
        return 10
      } else if (this.state.tipSelect === 'tip-20-flat') {
        return 20
      } else if (this.state.tipSelect === 'tip-cust') {
        return this.state.customTip
      } else {
        if (this.state.tipSelect === 'tip-25') { tip = 0.25 }
        else if (this.state.tipSelect === 'tip-20') { tip = 0.20 }
        else if (this.state.tipSelect === 'tip-18') { tip = 0.18 }
        else if (this.state.tipSelect === 'tip-no') { tip = 0.0 }
        return tip * this.total()
      }
    }
  }

  finalBalance() {
    return (this.total() * 0.75) + this.tip()
  }

  finalDogeBalance() {
    return this.finalBalance()/this.getDogeUSDRate()
  }

  selectStyles() {
    const styles = {
      width: '60px',
      menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
          width: "14px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555"
        }
      })
    }
    return styles
  }

    handleClickCreateWallet() {
	  const mnemonic = bip39.generateMnemonic()
	  console.log(mnemonic)
	  const seedBytes = null;//bip39.mnemonicToSeedSync(mnemonic);
	  const seed = mnemonic.toString();
	  //console.log(seed)
	  let xpriv;
	  let xpub;
	  bip39.mnemonicToSeed(mnemonic).then(seedObj => {
	      console.log('generating seed');
	      const seedBuffer = new Uint8Array(seedObj.buffer);
	      console.log(seedBuffer);
	      const hdkey = hdkeystuff.HDKey.fromMasterSeed(seedObj);
	      console.log('created hdkey')
	      console.log(hdkey)
	      xpriv = hdkey.privateExtendedKey;
	      xpub = hdkey.publicExtendedKey;
	      console.log(xpriv)
	      console.log(xpub)
	      window.alert("This is your wallet recovery seed. It is CRITICAL that you store this securely. It will allow you to access your funds and to use DogeExpress without loss of funds. You will be prompted to ensure that you have accurately recorded this phrase before continuing with setup: \n[\n"+seed+"\n]");
	      let conf = window.prompt('Re-enter the seed phrase words, each separated by a single space to confirm you have it stored correctly.')
	      //conf = seed;
	      while (seed != conf.trim()) {
		  window.alert('Something went wrong either when you created or re-entered your seed phrase. Please try again.');
		  conf = window.prompt('Re-enter the seed phrase words, each separated by a single space to confirm you have it stored correctly.');
	      }
	      if (seed == conf.trim()) {
		  AsyncStorage.setItem('xpriv', xpriv);
		  this.setState({walletXPriv: xpriv});

		  const walletUrl = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:8000/wallets.json' : `https://app.dogeexpress.io/wallets.json`;
		  const fullUrl = walletUrl;
		  fetch(fullUrl, {
		      method: "POST",
		      headers: {
			  'Content-Type': 'application/json', // Specify the content type as JSON
			  'Accept': 'application/json'
		      },
		      body: JSON.stringify({wallet: {owner_name: this.state.storeId, hd_public_key: xpub}}),
		  }).then(res => res.json()).then(wallet =>
		      {
			  window.alert(`Saved new wallet, xpub: ${wallet.hd_public_key}`);
		      }
		  );
	      }
	  })
    }

    handleEditStoreId() {
	let storeId = window.prompt('Enter Store Id', this.state.storeId);
	this.setState( {storeId} );
	AsyncStorage.setItem('store-id', storeId);
    }

    handleClickPayout() {
	var xpriv = this.state.walletXPriv; // process.argv[2]

	var hdPrivateKey = new bitcore.HDPrivateKey(xpriv)

	var tx = new bitcore.Transaction()
	var amount = 0;
	var tip_amount = 0;
	var pKeys = [];

	console.log('close out doge')
	const formData = new FormData();
	/*
	for (var i = 0; i < this.state.tipsPool.length; i++) {
	    formData.append("wallets[]", this.state.tipsPool[i])
	}
	*/
	let settleUrl = `https://app.dogeexpress.io/settle?store_id=${this.state.storeId}`;
        fetch(settleUrl, {
            method: "POST",
            body: formData
        }).then(res => res.json()).then(payload => {
            console.log(payload);
	    var utxos = payload.utxos;
	    var tips = payload.tips;
	    var rcptRetail = payload.wallets.retail; //'DSubVKTEvXJTE24y7xJR1z8unXbt41Vr3y';
	    var rcptService = payload.wallets.service;

	    for (var i = 0; i < utxos.length; i++) {
		var utxo = utxos[i];
		var derivedPriv = hdPrivateKey.deriveChild(utxo.keyDepth);
		//console.log(derivedPriv.privateKey);
		pKeys.push(derivedPriv.privateKey);

		// var oldTx = new bitcore.Transaction(utxo.hex)
		var uo = utxo // oldTx.outputs[utxo.n]
		// uo.txid = oldTx.id
 		uo.outputIndex = utxo.n
		tx.from(uo)
		amount += uo.satoshis
		var addr = derivedPriv.publicKey.toAddress().toString();
		console.log('public addr: ', addr)
		var tip = tips[addr] || 0;
		tip_amount += tip;
	    }
	    console.log('tips: ', tip_amount)

	    var min_amnt = 100_000_000
	    var fee_per = 2000000

	    var fee = fee_per * utxos.length
	    fee = Math.max(fee, 50_000_000); //min_amnt)

	    var amount_less_fee = amount - fee;

	    var amount_svc = Math.floor((amount_less_fee * 2)/100);
	    amount_svc = Math.max(amount_svc, min_amnt)
	    if (this.state.storeId === '20006501') {
		amount_svc = 0;
	    }

	    var amount_less_tips = amount_less_fee;
	    if (tip_amount > 0 && (this.state.tipsPool || '').trim().length > 0) {
		var tip_amount_sats = parseInt(tip_amount * 100_000_000);
		console.log('tip sats: ', tip_amount_sats);
		var tipAddrs = this.state.tipsPool.split(' ').filter(addr => addr.trim().length>0);
		console.log(tipAddrs)
		amount_less_tips = amount_less_fee - tip_amount_sats;
		var tipPer = Math.floor(tip_amount_sats / tipAddrs.length)
		console.log('tipPer: ', tipPer)
		for (var j = 0; j < tipAddrs.length-1; j++) {
		    console.log('adding tip ', j)
		    tx.to(tipAddrs[j], tipPer);
		    tip_amount_sats -= tipPer;
		    console.log('added')
		}
		console.log('adding final tip: ', tip_amount_sats)
		tx.to(tipAddrs[tipAddrs.length-1], tip_amount_sats);
	    }
	    var amount_retail = amount_less_tips - amount_svc;
	    amount_retail = Math.ceil(amount_retail);
	    console.log(amount_svc, amount_retail, fee)

	    tx.fee(fee)
	    if (amount_svc > 0) {
		tx.to(rcptService, amount_svc);
	    }
	    tx.to(rcptRetail, amount_retail);
    
	    var signed = tx.sign( pKeys )
	    console.log('valid? ', signed.isFullySigned())
	    try {
		var ok = window.confirm(`Payout will spend from ${amount/100_000_000} DOGE balance, ${amount_retail/100_000_000} DOGE to your wallet address: ${rcptRetail}, ${tip_amount} DOGE split amongst the tip pool [${this.state.tipsPool}] ${amount_svc/100_000_000} DOGE service fee to DogeExpress, and ${fee/100_000_000} DOGE network processing fee. This cannot be undone. Confirm to proceed.`);
		if (!ok) { return }
		var stx = tx.serialize()
		console.log(stx)
		fetch('/broadcast', {
		    method: "POST",
		    headers: {
			'Content-Type': 'application/json', // Specify the content type as JSON
			'Accept': 'application/json'
		    },
		    body: JSON.stringify({tx: stx}),
		}).then(res => res.json()).then(payload =>
		    {
			window.alert(`Paid`);
		    }
		);
	    } catch (error) {
		console.log("Got error trying to serialize tx")
		console.log(error)
		window.alert(`Got error trying to pay out DOGE balance:\n${error}`)
	    }
        });
    }

    render() {
	if (this.state.view === 'admin' && this.state.invoiceMode != 1) {
	    return (
		<div style={{backgroundColor: 'navy', height: '100vh'}}>
		    <div className="row" style={{backgroundColor: 'black', columnGap: '0px'}}>
			<div className="col-sm-12">
			    <div className="row">
				<div className="col-sm-2" style={{minHeight: '60px'}}>
				</div>
				<div className="col-sm-8"></div>
				<div className="col-sm-2"></div>
			    </div>
			</div>
		    </div>

		    <Row style={{padding: '10px'}}>
			<Col sm={1} />
			<Col style={{backgroundColor: 'white'}}>
			    <h1>Payouts Admin</h1>
			    <Row style={{marginBottom: '20px'}}>
				<Col>
				    Store Id: {this.state.storeId ? this.state.storeId: 'Not yet set'}
				</Col>
				<Col>
				    {
					//<input value="Edit" onClick={this.handleEditStoreId} type="button" />
				    }
				</Col>
			    </Row>
			    <hr/>
			    <Row style={{marginBottom: '20px'}}>
				<Col>
				    Wallet: {this.state.walletXPriv ? 'Set' : 'Not yet set'}
				</Col>
				<Col>
				    <input value="Create" onClick={this.handleClickCreateWallet} type="button" />
				</Col>
			    </Row>
			    <hr/>
			    <Row style={{marginBottom: '20px'}}>
				<Col>
				    Tips pool (list wallets of all recipients)<br/>
				    <textarea onChange={ (e)=>this.setState({tipsPool: e.target.value}) } />
				</Col>
				<Col>

				</Col>
			    </Row>
			    <hr/>
			    <Row style={{marginBottom: '20px'}}>
				<Col>
				    Balance: {this.state.balance/100_000_000} DOGE
				</Col>
				<Col>
				    <div><input value="Payout" onClick={this.handleClickPayout} type="button" /></div>
				</Col>
			    </Row>
			</Col>
			<Col sm={7} />
		    </Row>
		</div>
	    );
	}

	// Payment screen
		if (this.state.page === 3) {
			return (
        <>
        {
          this.state.rcvd ? (
            !this.state.remainder ? (
              <Canvas height={"1180px"} width={"1180px"} />
            ) : (<></>)
          ) : (<></>)
        }
				<div style={{backgroundColor: 'black', height: '100vh', color: 'white', fontSize: '30px'}}>
					<Row>
						<Col sm={{span: 6}} style={{paddingTop: '20px'}}>
					 		<Row style={{backgroundColor: '#4189C7', heightt: '200px', margin: '10px', borderRadius: '4px'}}>
								<Col>

                  {
                    this.state.storeId !== 0 ? (
                      <>
    									<Row>
    										<Col style={{textAlign: 'left'}} sm={{ span: 7 }}>Subtotal:</Col>
    										<Col style={{textAlign: 'right'}} sm={{ span: 5}}>${this.total().toFixed(2)}</Col>
    									</Row>
					
    									<Row>
    										<Col style={{textAlign: 'left', fontSize: '24px'}} sm={{ span: 9 }}>DogeExpress Discount 25%:</Col>
    										<Col style={{textAlign: 'right', fontSize: '24px'}} sm={{ span: 3 }}>{"-$" + (this.total()*0.25).toFixed(2)}</Col>
    									</Row>
                      </>
                    ) : (<></>)
                  }
								
									{
  									this.state.tipsMode === 'false' ? (<></>) : (
                      <Row>
    										<Col style={{textAlign: 'left'}} sm={{ span: 7 }}>Tip:</Col>
    										<Col style={{textAlign: 'right'}} sm={{ span: 5 }}>${this.tip().toFixed(2)}</Col>
    									</Row>
                    )
									}
								
                  {
                    this.state.storeId !== 0 ? (
    									<Row>
    										<Col style={{textAlign: 'left'}} sm={{ span: 7 }}>Balance due:</Col>
                        <Col style={{textAlign: 'right'}} sm={{ span: 5 }}>${this.finalBalance().toFixed(2)}</Col>
    									</Row>
                    ) : (<></>)
                  }

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 7 }}>{this.state.currency === 'ltc' ? 'Litecoin' : 'Dogecoin'} due:</Col>
										<Col style={{textAlign: 'right'}} sm={{ span: 5 }}>{this.state.currency === 'ltc' ? 'Ł' : 'Ð'}{this.finalDogeBalance().toFixed(this.state.currency === 'ltc' ? 6 : 2)}</Col>
									</Row>
								
								</Col>
							</Row>
						</Col>

						<Col sm={{span: 6}}>
								<Row style={{height: 'min(10vh,8vw)'}} />

								<Row style={{fontSize: '30px', textAlign: 'center'}}>
									<Col onClick={this.handleReset} style={{zIndex: '99999'}}><img alt='' src={icon} style={{width: '44px', height: '44px'}} /> DogeExpress payment</Col>
								</Row>

								<Row style={{height: 'min(8vh,6vw)'}} />

                {
									!this.state.rcvd ? (
                    <>
                      <Row>
                        {
                          this.state.qrCodeUrl ? <></> :
                          <div>Select Your Wallet</div>
                        }
                        <div style={{width: '95%', color: 'black'}}>
                           <Select styles={this.selectStyles()} name='walletApp' onChange={(sel) => {this.handleChooseWallet(sel)}}
                             options={[
                               {value: 'MyDoge', label: <img alt='' src={mydoge} style={{height: '45px'}}/>}, 
                               {value: 'Coinbase Wallet', label: <img alt='' src={coinbase} style={{height: '45px'}}/>}, 
                               {value: 'Coinbase', label: <span style={{fontWeight: '500'}}>Coinbase</span>}, 
                               {value: 'Exodus', label: <img alt='' src={exodus} style={{height: '45px'}}/>}, 
                               {value: 'Trust Wallet', label: <img alt='' src={trust} style={{height: '45px'}}/>}, 
                               {value: 'Atomic Wallet', label: <img alt='' src={atomic} style={{height: '45px'}}/>}, 
                               {value: 'Safe Pal', label: <img alt='' src={safepal} style={{height: '45px'}}/>}, 
                               {value: 'Other', label: <span style={{fontWeight: '500'}}>Other</span>}, 
                             ]}/>
                           </div>
                      </Row>

      								<Row style={{textAlign: 'center'}}>
      									<Col>Please scan this code to complete payment</Col>
      								</Row>

      								<Row style={{height: 'min(4vh,6vw)'}} />

      								<Row style={{textAlign: 'center'}}>
      									<Col>
      										<img alt='' style={{width: 'min(25vh,32vw)'}} src={this.state.qrCodeUrl} />
      									</Col>
      								</Row>

      								<Row style={{height: '50px'}} />
                    </>
                  ) : (
                    this.state.remainder ? (
                      <>
        								<Row style={{textAlign: 'center'}}>
                          <Col>Partial payment received Ð{this.state.rcvd}. Please scan this code to complete remaining payment of Ð{this.state.remainder.toFixed(2)}</Col>
        								</Row>

        								<Row style={{height: 'min(8vh,6vw)'}} />

        								<Row style={{textAlign: 'center'}}>
        									<Col>
        										<img alt='' style={{width: 'min(25vh,32vw)'}} src={this.state.qrCodeUrl} />
        									</Col>
        								</Row>

        								<Row style={{height: '50px'}} />
                      </>
                    ) : (
                      <></>
                    )
                  )
                }

								{
									this.state.rcvd ? (
                    <>
										<Row style={{textAlign: 'center'}}>
											<Col sm={{span: 2}} />
											<Col></Col>
											<Col sm={{span: 2}} />
										</Row>
                    </>
									) : (
										<Row style={{textAlign: 'center'}}>
											<Col sm={{span: 2}} />
											<Col style={{fontSize: '16px'}}>Send only Dogecoin (DOGE) to this address. Sending any other coins may result in permanent loss.</Col>
											<Col sm={{span: 2}} />
										</Row>
									)
								}
						</Col>
					</Row>
                {
  								this.state.rcvd ? (
                    !this.state.remainder ? (
                      <>
      									<Row style={{textAlign: 'center'}}>
      										<Col sm={{span: 2}} />
      										<Col style={{color: 'rgb(196,166,51)', fontSize: '65px'}}>Payment Received! Ð{this.state.rcvd.toFixed(2)}</Col>
      										<Col sm={{span: 2}} />
      									</Row>
      									<Row style={{textAlign: 'center'}}>
      										<Col sm={{span: 2}} />
      										<Col></Col>
      										<Col sm={{span: 2}} />
      									</Row>
                      </>
                    ) : (<></>)
  								) : (<></>)
                }
          <SupportedWallets handleClickSplash={this.handleClickSplash} />
				</div>
        </>
			);
		}

    // Tip screen
		if (this.state.page === 2) {
			return (
        <Tip storeId={this.state.storeId} tipSelect={this.state.tipSelect} handleTip={this.handleTip} total={this.total} customTip={this.state.customTip} handleContinueClick={this.handleContinueClick} />
			)
		}

    // Data entry screen
    if (this.state.page === 0) {
  		return (
        <DataEntry storeId={this.state.storeId} handleAdminClick={this.handleAdminClick} numerals={this.state.numerals} order={this.state.order} toggleEntry={this.toggleEntry} keypadToggle={this.state.keypadToggle} total={this.total} handleClickDoge={this.handleClickDoge} handleKeyClick={this.handleKeyClick} />
  		);
    } else {
      // Splash screen
      return (
        <Splash handleAdminClick={this.handleAdminClick} storeId={this.state.storeId} handleClickSplash={this.handleClickSplash} />
      )
    }
	}
}

export default Main;
